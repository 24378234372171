import { createSlice } from "@reduxjs/toolkit";
import {
  Aircraft,
  AircraftType,
  getAircrafts,
  getAircraftType,
  unsetAircraftType,
} from "../../api/flight/aircraftAPI";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

interface FlightState {
  aircrafts: Aircraft[];
  aircraft_type: AircraftType;
  is_loading: boolean;
}

const initialState: FlightState = {
  aircrafts: [],
  aircraft_type: unsetAircraftType,
  is_loading: false,
};

export const fetchAircrafts = createAsyncThunk<
  Aircraft[],
  Partial<string>,
  { rejectValue: AxiosError }
>("flight/fetchAircraft", async (id, { rejectWithValue }) => {
  const response = await getAircrafts();
  try {
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchAircraftType = createAsyncThunk<
  AircraftType,
  Partial<string>,
  { rejectValue: AxiosError }
>("flight/fetchAircraftType", async (type, { rejectWithValue }) => {
  const response = await getAircraftType(type);
  try {
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

// Then, handle actions in your reducers:
const flightSlice = createSlice({
  name: "flight",
  initialState,
  reducers: {
    setAircrafts: (state, action) => {
      state.aircrafts = action.payload;
    },
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAircrafts.pending, (state, action) => {
      state.is_loading = true;
    });
    builder.addCase(fetchAircraftType.pending, (state, action) => {
      state.is_loading = true;
    });
    builder.addCase(fetchAircrafts.fulfilled, (state, action) => {
      state.aircrafts = action.payload;
      state.is_loading = false;
    });
    builder.addCase(fetchAircraftType.fulfilled, (state, action) => {
      state.aircraft_type = action.payload;
      state.is_loading = false;
    });
  },
});

export default flightSlice.reducer;
