import axios from "axios";

export interface AircraftType {
  type: string;
  max_to_weight: number;
  forward_limit: number;
  aft_limit: number;
  crew_arm: number;
  aircraft_arm: number;
  baggage_arm: number;
  fuel_arm: number;
}

export const unsetAircraftType: AircraftType = {
  type: "",
  aft_limit: 0,
  aircraft_arm: 0,
  baggage_arm: 0,
  crew_arm: 0,
  forward_limit: 0,
  fuel_arm: 0,
  max_to_weight: 0,
};

export interface Aircraft {
  reg: string;
  type: string;
  empty_weight: number;
  cg_arm: number;
}

export const unsetAircraft: Aircraft = {
  reg: "",
  type: "",
  empty_weight: 0,
  cg_arm: 0,
};

const base_url = "https://niyad-com-flight.firebaseio.com/";

export async function getAircrafts() {
  const response = await axios.get(`${base_url}/aircraft.json`);
  const a = Object.keys(response.data).map((k) => response.data[k] as Aircraft);
  return a;
}

export async function getAircraftType(type: string) {
  const response = await axios.get(`${base_url}/aircrafttype/${type}.json`);
  const a = response.data as AircraftType;
  return a;
}
