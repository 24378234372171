import {
  createStyles,
  InputLabel,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    errorTitle: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.common.white,
    },
  })
);
interface Props {
  title: string;
  limit: string;
  actual: string;
  failed: boolean;
}

const FlightLimit: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={props.failed ? classes.errorTitle : classes.title}
        variant="subtitle1"
      >
        {props.title}
      </Typography>
      <Typography>{props.limit}</Typography>
      <InputLabel error={props.failed}>{props.actual}</InputLabel>
    </>
  );
};

export default FlightLimit;
