import { combineReducers } from "@reduxjs/toolkit";
import counterReducer from "features/counter/counterSlice";
import flightReducer from "features/flight/flightSlice";

const rootReducer = combineReducers({
  counter: counterReducer,
  flight: flightReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
