import React, { useEffect, useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { fetchAircrafts, fetchAircraftType } from "features/flight/flightSlice";
import { Aircraft, unsetAircraft } from "api/flight/aircraftAPI";
import FlightLimit from "./FlightLimit";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 80,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    weightCell: {
      margin: theme.spacing(2),
      maxWidth: 100,
    },
    table: {
      minWidth: 300,
    },
  })
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface CgTableRow {
  item: string;
  weight: number;
  arm: number;
  moment: number;
}

const initialCg: CgTableRow[] = [
  "Aircraft",
  "Crew",
  "Baggage",
  "ZFW",
  "Fuel",
  "TOW",
].map((i) => {
  return {
    item: i,
    weight: 0,
    arm: 0,
    moment: 0,
  };
});

export default function CgCalculation() {
  const classes = useStyles();

  const { aircrafts, aircraft_type, is_loading } = useSelector(
    (state: RootState) => state.flight
  );

  const [selectedAircraft, setSelectedAircraft] = useState<Aircraft>(
    unsetAircraft
  );
  const [aircraft, setAircraft] = useState("");
  const [instructor_weight, setInstructor_weight] = useState(95);
  const [student_weight, setStudent_weight] = useState(60);
  const [fuel, setFuel] = useState(68);
  const [baggage, setBaggage] = useState(0);
  const [cgTable, setCgTable] = useState<CgTableRow[]>(initialCg);
  const [tow, setTow] = useState(0);
  const [fwd_arm, setFwd_arm] = useState(0);
  const [aft_arm, setAft_arm] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAircraftType("P2008"));
    dispatch(fetchAircrafts(""));
  }, [dispatch]);

  useEffect(() => {
    const [a, c, b, z, f, t] = initialCg;
    f.weight = fuel * 0.72;
    c.weight = instructor_weight + student_weight;
    b.weight = baggage;

    if (aircraft_type.type !== "") {
      c.arm = aircraft_type.crew_arm;
      b.arm = aircraft_type.baggage_arm;
      f.arm = aircraft_type.fuel_arm;
    }

    if (selectedAircraft.reg !== "") {
      a.weight = selectedAircraft.empty_weight;
      a.arm = selectedAircraft.cg_arm;
      a.moment = a.weight * a.arm;

      f.moment = aircraft_type.fuel_arm * f.weight;

      c.moment = c.weight * aircraft_type.crew_arm;
      b.moment = b.weight * aircraft_type.baggage_arm;

      z.weight = selectedAircraft.empty_weight + c.weight + b.weight;
      z.moment = a.moment + c.moment + b.moment;
      z.arm = z.moment / z.weight;

      t.weight = z.weight + f.weight;
      t.moment = z.moment + f.moment;
      t.arm = t.moment / t.weight;
    }
    setCgTable([a, c, b, z, f, t]);
    setTow(t.weight);
    setFwd_arm(Math.min(z.arm, t.arm));
    setAft_arm(Math.max(z.arm, t.arm));
  }, [
    aircraft_type,
    baggage,
    fuel,
    instructor_weight,
    selectedAircraft,
    student_weight,
  ]);

  const handleAircraftChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const reg = event.target.value as string;
    setAircraft(reg);
    setSelectedAircraft(aircrafts.find((a) => a.reg === reg) ?? unsetAircraft);
  };

  const planes =
    !is_loading && aircrafts
      ? aircrafts.map((a) => (
          <MenuItem key={a.reg} value={a.reg}>
            {a.reg}
          </MenuItem>
        ))
      : null;

  return (
    <div>
      <Typography variant="h4">CG Calc {aircraft_type?.type}</Typography>
      <FormControl required className={classes.formControl}>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={aircraft}
          onChange={handleAircraftChange}
          className={classes.selectEmpty}
        >
          {planes}
        </Select>
        <FormHelperText>Aircraft</FormHelperText>
      </FormControl>
      <FormControl required className={classes.weightCell}>
        <Input
          id="fuel"
          className={classes.selectEmpty}
          value={fuel}
          type="number"
          onChange={(e) => setFuel(+e.target.value)}
          endAdornment={<InputAdornment position="end">Ltrs</InputAdornment>}
          aria-describedby="enter-fuel-in-litres"
          inputProps={{
            "aria-label": "weight",
          }}
        />
        <FormHelperText id="fuel-helper-text">Fuel</FormHelperText>
      </FormControl>
      <FormControl required className={classes.weightCell}>
        <Input
          id="baggage"
          className={classes.selectEmpty}
          value={baggage}
          type="number"
          onChange={(e) => setBaggage(+e.target.value)}
          endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
          aria-describedby="enter-baggage-in-litres"
          inputProps={{
            "aria-label": "weight",
          }}
        />
        <FormHelperText id="baggage-helper-text">Baggage</FormHelperText>
      </FormControl>
      <FormControl required className={classes.weightCell}>
        <Input
          className={classes.selectEmpty}
          id="instructor-weight"
          value={instructor_weight}
          type="number"
          onChange={(e) => setInstructor_weight(+e.target.value)}
          endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
          aria-describedby="standard-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
        />
        <FormHelperText id="instructor-weight-helper-text">
          Instructor Weight
        </FormHelperText>
      </FormControl>
      <FormControl required className={classes.weightCell}>
        <Input
          id="student-weight"
          className={classes.selectEmpty}
          value={student_weight}
          type="number"
          onChange={(e) => setStudent_weight(+e.target.value)}
          endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
          aria-describedby="standard-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
        />
        <FormHelperText id="student-weight-helper-text">
          Student Weight
        </FormHelperText>
      </FormControl>
      <Grid container className={classes.root} justify="center" spacing={2}>
        <Grid key="tow" item xs={4}>
          <Paper className={classes.paper}>
            <FlightLimit
              title="Max T/O"
              limit={aircraft_type.max_to_weight.toFixed(1)}
              actual={tow.toFixed(1)}
              failed={tow > aircraft_type.max_to_weight}
            />
          </Paper>
        </Grid>
        <Grid key="fwd" item xs={4}>
          <Paper className={classes.paper}>
            <FlightLimit
              title="Forward"
              limit={aircraft_type.forward_limit.toFixed(3)}
              actual={fwd_arm.toFixed(3)}
              failed={fwd_arm !== 0 && fwd_arm < aircraft_type.forward_limit}
            />
          </Paper>
        </Grid>
        <Grid key="aft" item xs={4}>
          <Paper className={classes.paper}>
            <FlightLimit
              title="Aft"
              limit={aircraft_type.aft_limit.toFixed(3)}
              actual={aft_arm.toFixed(3)}
              failed={aft_arm !== 0 && aft_arm > aircraft_type.aft_limit}
            />
          </Paper>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Item</StyledTableCell>
              <StyledTableCell align="right">Weight</StyledTableCell>
              <StyledTableCell align="right">Arm</StyledTableCell>
              <StyledTableCell align="right">Moment</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cgTable.map((r) => {
              return (
                <StyledTableRow key={r.item}>
                  <StyledTableCell component="th" scope="row">
                    {r.item}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {r.weight.toFixed(1)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {r.arm.toFixed(3)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {r.moment.toFixed(3)}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
