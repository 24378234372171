import React from "react";
import { Provider } from "react-redux";
import { Switch, Route } from "react-router";
import { Box, Container } from "@material-ui/core";
import CgCalculation from "components/flight/CgCalculation";
// import AircraftAdd from "components/flight/AircraftAdd";
import store from "app/store";
import Home from "components/Home";
import { BrowserRouter } from "react-router-dom";
import AircraftAdd from "components/flight/AircraftAdd";

export default function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Container maxWidth="sm">
          {/* <AircraftAdd /> */}
          <Box my={4}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/flight" component={CgCalculation} />
              <Route exact path="/flight/aircraft" component={AircraftAdd} />
            </Switch>
          </Box>
        </Container>
      </Provider>
    </BrowserRouter>
  );
}
