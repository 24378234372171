import { Button, Typography } from "@material-ui/core";
import { Aircraft, AircraftType } from "api/flight/aircraftAPI";
import axios from "axios";
import React from "react";

const p2008: AircraftType = {
  type: "P2008",
  aft_limit: 2.02,
  aircraft_arm: 1.873,
  baggage_arm: 2.417,
  crew_arm: 1.9,
  forward_limit: 1.842,
  fuel_arm: 2.209,
  max_to_weight: 600,
};

const vkt: Aircraft = {
  reg: "VH-VKT",
  type: "P2008",
  empty_weight: 396,
  cg_arm: 1.873,
};

const vku: Aircraft = {
  reg: "VH-VKU",
  type: "P2008",
  empty_weight: 394,
  cg_arm: 1.872,
};

const vks: Aircraft = {
  reg: "VH-VKS",
  type: "P2008",
  empty_weight: 396,
  cg_arm: 1.874,
};

const vkv: Aircraft = {
  reg: "VH-VKV",
  type: "P2008",
  empty_weight: 395,
  cg_arm: 1.873,
};

const ofk: Aircraft = {
  reg: "VH-OFK",
  type: "P2008",
  empty_weight: 394.5,
  cg_arm: 1.874,
};

const aircrafts = [vkt, vks, vku, vkv, ofk];

function create() {
  aircrafts.forEach((element) => {
    const url = `https://niyad-com-flight.firebaseio.com/aircraft/${element.reg}.json`;
    axios.put(url, element);
  });

  const url = `https://niyad-com-flight.firebaseio.com/aircrafttype/${p2008.type}.json`;
  axios.put(url, p2008);
}

const AircraftAdd = () => {
  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Aircrafts
      </Typography>
      <Button
        onClick={() => {
          create();
        }}
      >
        Create Some Planes!
      </Button>
    </>
  );
};

export default AircraftAdd;
